import { UserRole } from '@quickcommerceltd/zipp'
import { format } from 'date-fns'
import { PurchaseOrder, PurchaseOrderProduct } from '../../_types'
import { PurchaseOrderExportData } from '../_types'

export const generatePurchaseOrderExportData = (
  purchaseOrder: PurchaseOrder | undefined,
  purchaseOrderProducts: PurchaseOrderProduct[] | undefined,
  userRole: UserRole = UserRole.SITE_ASSOCIATE
): PurchaseOrderExportData | undefined => {
  if (!purchaseOrder || !purchaseOrderProducts) {
    return
  }

  return {
    orderNumber: purchaseOrder.id,
    accountNumber: purchaseOrder.supplier?.account || '',
    supplierName: purchaseOrder.supplier?.name,
    supplierAddress: purchaseOrder.supplier?.address,
    shippingAddress: purchaseOrder.address,
    date: purchaseOrder.createdAt ? format(new Date(purchaseOrder.createdAt), 'dd/MM/yyyy') : '',
    expectedDate: purchaseOrder.expectedDate ? format(new Date(purchaseOrder.expectedDate), 'dd/MM/yyyy') : '',
    lineItems: purchaseOrderProducts,
    notes: purchaseOrder.notes,
    deliveryNotes: purchaseOrder.deliveryNotes,
    internalNotes: purchaseOrder.internalNotes,
    showInternalNotes: userRole === UserRole.PROCUREMENT_ASSOCIATE,
  }
}
