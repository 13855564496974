import { Box, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

// TODO: migrate to emotion and styled
export const ReloadButton = () => {
  const useStyles = makeStyles()(() => ({
    reload: {
      color: '#AAA',
    },
    footer: {
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
    },
  }))

  const { classes } = useStyles()
  return (
    <Box className={classes.footer}>
      <Button className={classes.reload} variant="text" onClick={() => window.location.reload()}>
        reload
      </Button>
    </Box>
  )
}
