import { useCallback } from 'react'
import { Permission, PERMISSIONS } from '../../consts/PERMISSIONS'
import { useMyUser } from '../useMyUser'

export const useHasPermission = () => {
  const role = useMyUser((state) => state.user?.role)

  return useCallback(
    (permission: Permission): boolean => {
      if (!PERMISSIONS[permission].length) return true
      if (!role) return false
      return PERMISSIONS[permission].includes(role)
    },
    [role]
  )
}
