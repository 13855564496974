import { Alert as MuiAlert, Snackbar } from '@mui/material'
import { memo } from 'react'

export enum AlertSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

interface IProps {
  show: boolean
  message: string
  autoHideDuration?: number
  onClose: () => void
  severity?: AlertSeverity
  vertical?: 'top' | 'bottom'
}

export interface ISnack {
  show: boolean
  message: string
  severity?: AlertSeverity
}

const ZSnack = memo((props: IProps) => (
  <Snackbar
    open={props.show}
    autoHideDuration={props.autoHideDuration || 5000}
    anchorOrigin={{
      vertical: props.vertical ?? 'bottom',
      horizontal: 'center',
    }}
    onClose={props.onClose}
    ClickAwayListenerProps={{
      mouseEvent: false,
      touchEvent: false,
    }}
  >
    <MuiAlert elevation={6} variant="filled" onClose={props.onClose} severity={props.severity || AlertSeverity.SUCCESS}>
      {props.message}
    </MuiAlert>
  </Snackbar>
))

export default ZSnack
