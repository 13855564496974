import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import { Badge, BottomNavigation, BottomNavigationAction, Box, styled } from '@mui/material'
import { FC } from 'react'
import { ROUTES } from '../../consts/Routes'
import { useMyUser, useMyWarehouse } from '../../hooks'
import SideDrawer from '../SideDrawer/SideDrawer'
import { StatusBar } from '../StatusBar/StatusBar'
import { CrossHairIcon, HamburgerBarIcon } from '../Svg'
import { themePalette } from '../Theme'
import { useTabbar } from './useTabbar'
import { useTabbarConfig } from './useTabbarConfig'

export const TABBAR_HEIGHT = 64

export const Tabbar: FC = () => {
  const { queue, currentPath, isSidebarOpen, isPutawayTaskWaiting, handleNavigationChange, toggleSidebar } = useTabbar()
  const isHidden = useTabbarConfig((state) => state.isHidden)
  const shortName = useMyWarehouse((state) => state.warehouse?.shortName)
  const user = useMyUser((state) => state.user)

  if (isHidden) return null

  return (
    <>
      <TabbarSpacer />
      <TabbarWrapper>
        <TabBarContainer>
          <BottomNavigation value={currentPath} onChange={handleNavigationChange} showLabels>
            <BottomNavigationAction
              value={ROUTES.QUEUE_LISTENING}
              icon={
                <PulsatingBadge badgeContent={queue?.tasks?.length} max={9} color="error">
                  <ShoppingBasketOutlinedIcon sx={{ color: themePalette.secondary.contrastText }} />
                </PulsatingBadge>
              }
            />
            <BottomNavigationAction
              value={ROUTES.PUTAWAY}
              icon={
                <PulsatingBadge badgeContent={Number(isPutawayTaskWaiting)} variant="dot" color="primary">
                  <UnarchiveOutlinedIcon sx={{ color: themePalette.secondary.contrastText }} />
                </PulsatingBadge>
              }
            />
            <BottomNavigationAction
              value={ROUTES.STOCK_MOVEMENT_EXPLORER}
              icon={<CrossHairIcon sx={{ color: themePalette.secondary.contrastText }} />}
            />
            <BottomNavigationAction
              value=""
              icon={<HamburgerBarIcon sx={{ color: themePalette.secondary.contrastText }} />}
              onClick={(e) => {
                e.currentTarget.blur()
                toggleSidebar()
              }}
            />
          </BottomNavigation>
          <StatusBar email={user?.email ?? ''} shortName={shortName ?? ''} />
        </TabBarContainer>
      </TabbarWrapper>
      <SideDrawer open={isSidebarOpen} onClose={toggleSidebar} />
    </>
  )
}

const TabbarSpacer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: TABBAR_HEIGHT,
}))

const TabbarWrapper = styled(Box)(() => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: TABBAR_HEIGHT,
  boxSizing: 'border-box',
  zIndex: 10,
}))

const TabBarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: theme.palette.common.white,
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset',

  '& .MuiBottomNavigationAction-root': {
    minWidth: 'auto',
  },

  '& .MuiBottomNavigationAction-root svg': {
    fontSize: '1.4rem',
  },

  '& .MuiBottomNavigationAction-root .MuiBottomNavigationAction-label': {
    fontSize: 10,
    whiteSpace: 'nowrap',
  },
}))

const PulsatingBadge = styled(Badge)(({ badgeContent }) => ({
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(0.9) translate(30%, -40%)',
    },
    '50%': {
      transform: 'scale(0.75) translate(30%, -40%)',
    },
    '100%': {
      transform: 'scale(0.9) translate(30%, -40%)',
    },
  },

  '.MuiBadge-badge': {
    animation: !!badgeContent ? 'pulsate 1.5s infinite ease-in-out' : 'inherit',
  },
}))
