import { useContext } from 'react'
import { useMyUser } from '../../hooks'
import FeatureFlagContext from './context'
import { getFeatureFlagValues } from './utilities'

const useFeatureFlag = (flags: string[], warehouseId: string = useMyUser.getState().user?.warehouseId ?? '') => {
  const config = useContext(FeatureFlagContext)

  return {
    values: getFeatureFlagValues(flags, warehouseId, config),
    config,
    warehouseId,
  }
}

export default useFeatureFlag
