import { useCallback, useEffect, useState } from 'react'
import { SearchFilter } from '../../Containers/PurchaseOrders/PurchaseOrdersV2/_types/searchFilter'
import { logSentryError } from '../../utils/logHelper'

const ZIM_LOCAL_STORAGE_KEY = 'ZIM'

export interface CompletedLocationsCount {
  priority: number
  overall: number
}
export interface ZimLocalStorage {
  purchaseOrderSearchFilter: SearchFilter
  cycleCountConfirmedMovements: string[]
  cycleCountV3CompletedSkus: Record<string, string[]>
  completedLocationsCounts: CompletedLocationsCount
}

export const useLocalStorage = <K extends keyof ZimLocalStorage>(
  key: K,
  initialValue?: ZimLocalStorage[K]
): [ZimLocalStorage[K] | undefined, Function] => {
  const getLocalStorageValue = useCallback((): ZimLocalStorage | undefined => {
    if (typeof window === 'undefined') return undefined

    try {
      const item = window.localStorage.getItem(ZIM_LOCAL_STORAGE_KEY)
      const parsedZimStorage = item ? (JSON.parse(item) as ZimLocalStorage) : undefined

      return parsedZimStorage
    } catch (error) {
      logSentryError(error)
      return undefined
    }
  }, [])

  const setLocalStorageValue = useCallback((value: Partial<ZimLocalStorage>) => {
    if (typeof window === 'undefined') return
    if (value === undefined) return

    window.localStorage.setItem(ZIM_LOCAL_STORAGE_KEY, JSON.stringify(value))
  }, [])

  const [storedValue, setStoredValue] = useState(
    getLocalStorageValue()?.[key] !== undefined && getLocalStorageValue()?.[key] !== null
      ? getLocalStorageValue()?.[key]
      : initialValue
  )

  const setValue = (value: string | Function) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value

      // Save state
      setStoredValue(valueToStore)

      // Save to local storage
      setLocalStorageValue({
        ...getLocalStorageValue(),
        [key]: valueToStore,
      })
    } catch (error) {
      logSentryError(error)
      console.error(error) //eslint-disable-line
    }
  }

  // subscribe to local storage update events
  useEffect(() => {
    const onStorageChange = (event: StorageEvent) => {
      if (event.storageArea !== localStorage) return
      if (event.key !== ZIM_LOCAL_STORAGE_KEY) return

      const localStorageValue = getLocalStorageValue()?.[key]
      if (!localStorageValue) return

      setStoredValue(localStorageValue)
    }

    window.addEventListener('storage', onStorageChange)
    return () => window.removeEventListener('storage', onStorageChange)
  }, [key, getLocalStorageValue])

  return [storedValue, setValue]
}
