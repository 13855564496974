import { PurchaseOrderPicklistProduct } from '../../../../db/purchaseOrderPicklist/types'

export const aggregatePickedItems = (picklist: PurchaseOrderPicklistProduct[]) => {
  return picklist.reduce((acc: Record<string, PurchaseOrderPicklistProduct>, product) => {
    let newProduct = product

    const expiryDate = newProduct.expiryDate.toDate().toISOString()
    const mapKey = `${newProduct.from}_${newProduct.sku}_${expiryDate}`

    if (acc[mapKey]) {
      newProduct = {
        ...acc[mapKey],
        quantity: (acc[mapKey].quantity || 0) + newProduct.quantity,
      }
    }

    return {
      ...acc,
      [mapKey]: newProduct,
    }
  }, {})
}
