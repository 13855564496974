import React, { Dispatch, SetStateAction, createContext, useState } from 'react'

export interface Props {
  children?: React.ReactNode
}

export const ToggleUTCContext = createContext<{
  showUTC: boolean
  setShowUTC: Dispatch<SetStateAction<boolean>>
}>({
  showUTC: false,
  setShowUTC: () => ({}),
})

const Provider = ({ children }: Props) => {
  const [showUTC, setShowUTC] = useState<boolean>(false)
  return <ToggleUTCContext.Provider value={{ showUTC, setShowUTC }}>{children}</ToggleUTCContext.Provider>
}

export default Provider
