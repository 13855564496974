import { Device } from '@quickcommerceltd/zipp'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { zimBackendDb } from '../../firebase'
import { getDeviceId } from '../../utils/dataStore'
import { setDeviceStatus } from '../../utils/deviceStatus'
import { useMyUser } from '../useMyUser'

interface MyDeviceState {
  device?: Device
  isInitializing: boolean
  subscribe: () => void
  unsubscribe?: () => void
  startHeartbeat: () => void
  stopHeartbeat?: () => void
}

export const useMyDevice = create<MyDeviceState>((set) => ({
  isInitializing: true,
  subscribe: () => {
    const deviceId = getDeviceId()
    const unsubscribeFirestore = onSnapshot(
      doc(zimBackendDb, 'devices', deviceId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return
        if (!snapshot.exists()) {
          setDeviceStatus({ isOnline: false })
          return
        }

        const device = snapshot.data() as Device
        set({
          device,
          ...(device && { isInitializing: false }),
        })
      },
      () => {
        set({ device: undefined, isInitializing: false })
      }
    )

    set({
      unsubscribe: () => {
        set({ device: undefined, isInitializing: true })
        unsubscribeFirestore()
      },
    })
  },
  startHeartbeat: () => {
    const updateDeviceStatus = async () => {
      const user = useMyUser.getState().user

      if (!user) return

      await setDeviceStatus({
        isOnline: true,
        pushToken: window.pushToken, // injected by the expo wrapper. undefined in web browser,
        userId: user?.id,
        userEmail: user?.email,
        userName: user?.name,
        warehouseId: user?.warehouseId,
      })
    }

    updateDeviceStatus()
    const heartbeat = setInterval(updateDeviceStatus, 30_000, true)

    set({
      stopHeartbeat: () => clearInterval(heartbeat),
    })
  },
}))
