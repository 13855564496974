export enum ProductStates {
  ALL = 'all',
  NOT_PICKED = 'not-picked',
  PARTIALLY_PICKED = 'partially-picked',
  PICKED = 'picked',
}

export enum ORDER_COLLECTIONS {
  CO = 'customerOrders',
  PO = 'purchaseOrders',
}
