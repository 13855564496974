import { Button } from '@mui/material'
import { LoadingSpinner } from '../LoadingSpinner'

import type { Props } from './types'

const LoadingButton = ({ children, loading, onClick, ...others }: Props) => {
  const handleClick = (event: any) => {
    if (loading) {
      return
    }

    onClick?.(event)
  }

  return (
    <Button {...others} onClick={handleClick}>
      {loading ? <LoadingSpinner size={22} /> : children}
    </Button>
  )
}

export default LoadingButton
