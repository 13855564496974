interface ErrorWithSeverity {
  details: { severity: number }
}

export function isErrorWithSeverity(err: unknown): err is ErrorWithSeverity {
  return (
    typeof err === 'object' &&
    err !== null &&
    'details' in err &&
    typeof (err as { details?: unknown }).details === 'object' &&
    err.details !== null &&
    typeof (err.details as { severity?: unknown }).severity === 'number'
  )
}
