import { Warehouse } from '@quickcommerceltd/zipp'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { zimBackendDb } from '../../firebase'

interface MyWarehouseState {
  warehouse?: Warehouse
  isInitializing: boolean
  subscribe: (userId: string) => void
  unsubscribe?: () => void
}

export const useMyWarehouse = create<MyWarehouseState>((set) => ({
  isInitializing: true,
  subscribe: (warehouseId) => {
    const unsubscribeFirestore = onSnapshot(
      doc(zimBackendDb, 'warehouses', warehouseId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return
        if (!snapshot.exists()) return

        const warehouse = snapshot.data() as Warehouse

        set({
          warehouse,
          ...(warehouse && { isInitializing: false }),
        })
      },
      () => {
        set({ warehouse: undefined, isInitializing: false })
      }
    )

    set({
      unsubscribe: () => {
        set({ warehouse: undefined, isInitializing: true })
        unsubscribeFirestore()
      },
    })
  },
}))
