export enum Flows {
  AUTO_INBOUND = 'AUTO_INBOUND',
  CYCLE_COUNT_V3 = 'CYCLE_COUNT_V3',
  EXPIRY_SWEEPER = 'EXPIRY_SWEEPER',
  INBOUND_V3 = 'INBOUND_V3',
  LOCATIONS_V3 = 'LOCATIONS_V3',
  LOGIN = 'LOGIN',
  MOVE_SKU = 'MOVE_SKU',
  PICKING_V2 = 'PICKING_V2',
  PICKING_V3 = 'PICKING_V3',
  PUTAWAY = 'PUTAWAY',
  PURCHASE_ORDER_DETAILS_V3 = 'PURCHASE_ORDER_DETAILS_V3',
  REPORT_DISCREPANCY = 'REPORT_DISCREPANCY',
  STOCK_ADJUSTMENT = 'STOCK_ADJUSTMENT',
  USERS = 'USERS',
}
