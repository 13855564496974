import { ListItem, ListItemIcon, ListItemSecondaryAction, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

interface MenuLinkProps {
  icon: JSX.Element
  badge?: boolean | JSX.Element
  to?: string
  onClick?: () => void
}

// TODO: migrate to emotion and styled
const useStyles = makeStyles()((theme) => ({
  root: {
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': { '& $text': { color: 'inherit' } },
    borderRadius: 0,
  },
  icon: { color: 'inherit', minWidth: 'auto', marginRight: 15 },
  text: { fontSize: 18, color: '#444', fontWeight: 600 },
  badge: { pointerEvents: 'none' },
}))

const MenuItem: React.FC<MenuLinkProps> = ({ icon, children, badge, to, onClick }) => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
    if (to) {
      navigate(to)
    }
  }, [navigate, onClick, to])

  return (
    <ListItem button onClick={handleClick} className={classes.root} color="initial">
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <Typography component="span" className={classes.text}>
        {children}
      </Typography>
      {badge && <ListItemSecondaryAction className={classes.badge}>{badge}</ListItemSecondaryAction>}
    </ListItem>
  )
}

export default MenuItem
