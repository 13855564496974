import { DocumentChange, QueryDocumentSnapshot } from 'firebase/firestore'
import { Action, ActionType } from './actions'
import { addItem, updateItem } from './utils'

interface ReducerState {
  elements: QueryDocumentSnapshot[]
  loading: boolean
  lastElement: QueryDocumentSnapshot | null
  hasMoreElements: boolean
  after: QueryDocumentSnapshot | null
  error: Error | null
}

export const initialState: ReducerState = {
  elements: [],
  loading: false,
  lastElement: null,
  hasMoreElements: true,
  after: null,
  error: null,
}

export const reducer = (state: ReducerState, action: Action) => {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        loading: true,
      }
    case ActionType.LOADED:
      const { snapshot, pageSize } = action.payload
      const elements = [...state.elements]
      const changes = snapshot.docChanges()

      changes.forEach((change: DocumentChange) => {
        if (change.type === 'added') {
          const method = changes.length === pageSize ? 'append' : 'prepend'
          addItem(change.doc, elements, method)
        }

        if (change.type === 'modified') {
          updateItem(change.doc, elements)
        }
      })
      const newElements = changes.filter((change) => change.type === 'added').length
      return {
        ...state,
        loading: false,
        elements,
        lastElement: elements[elements.length - 1],
        hasMoreElements: newElements === pageSize,
      }
    case ActionType.LOAD_MORE:
      return {
        ...state,
        after: state.lastElement,
      }
    case ActionType.ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ActionType.RESET:
      return initialState
    default:
      return state
  }
}
