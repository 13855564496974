import { Query } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

interface QueryParams {
  limit?: number
  orderBy?: string[][]
}

export const useQuery = <T, P>(
  queryGetterFn: (...args: [warehouseId: string, params: Partial<P>, queryParams: Partial<QueryParams>]) => Query<T>
) => {
  return (id: string | undefined = '', params: Partial<P> = {}, queryParams: Partial<QueryParams> = {}) => {
    const query = useMemo(() => {
      const values = Object.values(params)

      if (!id || (values.length && !values.every((value) => typeof value !== 'undefined'))) {
        return
      }

      return queryGetterFn(id, params, queryParams)
    }, [id, params])

    return useCollectionData<T>(query)
  }
}
