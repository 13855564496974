import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { zimBackendDb } from '../../firebase'

export interface AppConfig {
  minVersion: string
  isInMaintenanceMode: boolean
}

export interface AppConfigStore {
  appConfig?: AppConfig
  isInitializing: boolean
  subscribe: () => void
  unsubscribe?: () => void
}

export const useAppConfig = create<AppConfigStore>((set) => ({
  isInitializing: true,
  subscribe: () => {
    const unsubscribeFirestore = onSnapshot(
      doc(zimBackendDb, 'appConfig', 'appConfig'),
      async (snapshot) => {
        if (!snapshot.exists()) return

        const appConfig = snapshot.data() as AppConfig

        set({
          appConfig,
          ...(appConfig && { isInitializing: false }),
        })
      },
      () => {
        set({ appConfig: undefined, isInitializing: false })
      }
    )

    set({
      unsubscribe: () => {
        set({ appConfig: undefined, isInitializing: true })
        unsubscribeFirestore()
      },
    })
  },
}))
