import { User } from '@quickcommerceltd/zipp'
import { APP_VERSION } from '../../consts/version'
import { useMyUser } from '../../hooks'
import { logCallable } from './_callables/logCallable'
import { LogActionsMap } from './_types/LogAction'
import { LogCategory } from './_types/LogCategory'

const getPayload = (
  category: LogCategory,
  action: string,
  message: string,
  data: Record<string, unknown>,
  user: User | undefined
) => {
  // serialize error object
  if (data.error instanceof Error) {
    data.error = {
      ...data.error,
      name: data.error.name,
      message: data.error.message,
      stack: data.error.stack,
    }
  }

  const warehouseId = user?.warehouseId || 'unknown'

  return {
    warehouseId,
    category,
    action,
    message: `[${warehouseId}/${category}/${action}] ${message}`, // !IMPORTANT! This will be used by log based metrics
    data,
    user,
    metadata: {
      version: APP_VERSION,
      navigator: {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        cookieEnabled: navigator.cookieEnabled,
        language: navigator.language,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        onLine: navigator.onLine,
      },
    },
    createdAt: new Date(),
  }
}

export const log = <T extends LogCategory>(
  category: T,
  action: LogActionsMap[T],
  message: string,
  data: Record<string, unknown> = {}
) => {
  const user = useMyUser.getState().user
  const payload = getPayload(category, action, message, data, user)

  logCallable(payload).catch(() => {})
}
