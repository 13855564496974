import { PurchaseOrderProduct } from '@quickcommerceltd/zipp'

export const getUniqueSkuIds = (items: PurchaseOrderProduct[]) => {
  return items.reduce((acc: string[], item) => {
    if (item.quantityPicked) {
      acc.push(item.id)
    }

    return acc
  }, [])
}
