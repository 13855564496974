import { User } from '@quickcommerceltd/zipp'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { zimBackendDb } from '../../firebase'

interface MyUserState {
  user?: User
  isInitializing: boolean
  subscribe: (userId: string) => void
  unsubscribe?: () => void
}

export const useMyUser = create<MyUserState>((set) => ({
  isInitializing: true,
  subscribe: (userId) => {
    const unsubscribeFirestore = onSnapshot(
      doc(zimBackendDb, 'users', userId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return
        if (!snapshot.exists()) return

        const user = snapshot.data() as User
        set({
          user,
          ...(user && { isInitializing: false }),
        })
      },
      () => {
        set({ user: undefined, isInitializing: false })
      }
    )

    set({
      unsubscribe: () => {
        set({ user: undefined, isInitializing: true })
        unsubscribeFirestore()
      },
    })
  },
}))
