import { PendingAllocation } from '@quickcommerceltd/zipp'
import { collection, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericConverter'

const pendingAllocationsConverter = new GenericDataConverter<PendingAllocation>()

interface Props {
  warehouseId?: string
  orderId?: string
}

export const usePendingAllocations = ({ warehouseId, orderId }: Props) => {
  const ref = useMemo(() => {
    if (!warehouseId) return undefined
    return query(
      collection(zimBackendDb, `warehouses/${warehouseId}/pendingAllocations`),
      ...(orderId ? [where('orderId', '==', orderId)] : [])
    ).withConverter(pendingAllocationsConverter)
  }, [warehouseId, orderId])

  return useCollectionData<PendingAllocation>(ref)
}
