import { CatalogProduct } from '@quickcommerceltd/zipp'
import { collection, query, where } from 'firebase/firestore'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericConverter'

const converter = new GenericDataConverter<CatalogProduct>()

export const useAllRegistrationProducts = () => {
  return useCollectionDataOnce<CatalogProduct>(
    query(collection(zimBackendDb, `catalogProducts`), where('requiresRegistration', '==', true)).withConverter(
      converter
    )
  )
}
