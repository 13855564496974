import { grey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, StyledEngineProvider, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { FC } from 'react'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
  }
}

export const COLORS = {
  lightRed: '#fd1004',
  lightGreen: '#55aa68',
  green: '#1be426',
  lightOrange: '#f19c4d',
  pinkColor: '#f1b7b5',
  autoGeneratedPO: '#01ad4a78',
  autoGeneratedPOdelayed: '#de4f4f8a',
}

export const themePalette = {
  background: {
    light: '#F9FAFB',
    default: '#fff',
    dark: '#002C3C',
  },
  primary: {
    light: '#B9CED8',
    main: '#608CA1',
    dark: '#3E5D6C',
    contrastText: '#fff',
  },
  secondary: {
    light: '#F8F9FA',
    main: '#E9F0F3',
    dark: '#B9CED8',
    contrastText: '#000',
  },
  grey: {
    50: '#EBEBEB',
    100: '#D4D4D4',
    700: '#464646',
  },
  text: {
    primary: '#002533',
    secondary: '#3E5D6C',
  },
  success: {
    light: '#E5FFF8',
    main: '#00805C',
  },
  warning: {
    light: '#FFF0D9',
    main: '#F59401',
    dark: '#A15E07',
  },
  error: {
    light: '#FFD9D9',
    main: '#D83232',
    dark: '#990F0F',
  },
}

const theme = createTheme({
  palette: themePalette,
  typography: {
    fontFamily: [
      'Manrope',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    allVariants: {
      color: '#444444',
    },
    h4: {
      fontSize: 30,
      fontWeight: 700,
    },
    h5: {
      fontWeight: 500,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
      variants: [
        {
          props: { severity: 'info' },
          style: {
            backgroundColor: themePalette.secondary.main,
            color: themePalette.text.primary,
            '& .MuiAlert-icon': {
              color: themePalette.text.primary,
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          color: themePalette.text.primary,
          backgroundColor: themePalette.secondary.main,
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '140%',
          padding: '8px 16px',
        },
        popupIndicator: {
          '& svg': {
            fill: themePalette.primary.main,
          },
          '&.Mui-disabled svg': {
            fill: themePalette.primary.light,
          },
        },
        paper: {
          marginTop: '4px',
          borderRadius: '6px',
          border: `1px solid ${themePalette.primary.main}`,
          background: '#FFF',
          boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
          color: themePalette.text.primary,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '800',
          lineHeight: '140%',
          letterSpacing: '-0.2px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          border: `1px solid ${themePalette.primary.light}`,
          background: '#fff',
          color: themePalette.text.primary,
          fontWeight: 600,
          '&:hover': {
            background: '#fff',
            border: `1px solid ${themePalette.primary.main}`,
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before ': {
            borderBottom: 'none',
          },
          '&.Mui-disabled': {
            background: themePalette.background.default,
            borderColor: themePalette.secondary.main,
            color: themePalette.text.secondary,
            '&:hover': {
              background: themePalette.background.default,
              borderColor: themePalette.secondary.main,
            },
            '&:before': {
              border: 'none',
            },
          },
          '&.Mui-focused': {
            background: '#fff',
            border: `1px solid ${themePalette.primary.dark}`,
          },
        },
        underline: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: themePalette.text.secondary,
          fontSize: '13px',
          fontWeight: 600,
          '&.Mui-focused': {
            color: themePalette.text.secondary,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 800,
          padding: '5px 32px',
          textTransform: 'none',
        },
        sizeLarge: {
          borderRadius: 10,
          paddingTop: 16,
          paddingBottom: 16,
        },
        contained: {
          boxShadow: 'none',
          '&.Mui-disabled': {
            color: grey[400],
            backgroundColor: grey[200],
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: grey[800],
            background: grey[300],
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: themePalette.primary.main,
            color: themePalette.secondary.light,
          },
          '&.Mui-button': {
            '&:hover': {
              backgroundColor: themePalette.primary.main,
              color: themePalette.secondary.light,
            },
          },
        },
      },
    },
  },
})

export const ThemeProvider: FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <>
        <CssBaseline />
        {children}
      </>
    </MuiThemeProvider>
  </StyledEngineProvider>
)
