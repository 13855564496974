import { PurchaseOrder } from '@quickcommerceltd/zipp'
import { useMemo } from 'react'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { getPurchaseOrderRef } from '../../db/purchaseOrders'

export const usePurchaseOrder = (id: string) => {
  // Purchase Order
  // ==================================================
  const purchaseOrderRef = useMemo(() => (id ? getPurchaseOrderRef({ id }) : undefined), [id])

  return useDocumentDataOnce<PurchaseOrder>(purchaseOrderRef)
}
