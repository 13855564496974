import { useState } from 'react'
import { updateUserCallable } from '../Containers/Users/_callables/updateUserCallable'
import { LogAction, LogCategory, log } from '../utils/logHelper'
import { zimBackendAuth } from './'

/**
 * Hook for Signup
 */
export const useSignupComplete = () => {
  const [signupCompleteState, setSignupCompleteState] = useState('')

  const signupComplete = async ({
    fullName,
    email,
    warehouseId,
  }: {
    fullName: string
    email: string
    warehouseId: string
  }) => {
    if (!zimBackendAuth.currentUser) {
      return
    }

    if (email && fullName.trim() && warehouseId) {
      setSignupCompleteState('submitting')

      await updateUserCallable({
        userId: zimBackendAuth.currentUser.uid,
        user: {
          name: fullName,
          warehouseId,
        },
      })
      log(LogCategory.AUTH, LogAction.SIGNUP_COMPLETE, 'Completed Signup', { email, fullName, warehouseId })
    } else {
      setSignupCompleteState('All fields are required!')
    }
  }

  return {
    signupCompleteState,
    signupComplete,
  }
}
