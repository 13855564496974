import { captureMessage } from '@sentry/react'
import { useMyDevice, useMyUser } from '../../hooks'

export const logSentryMessage = (message: string, metadata = {}) => {
  try {
    const user = useMyUser.getState().user
    const device = useMyDevice.getState().device
    const data = { ...user, deviceId: device?.id }
    captureMessage(message, { user: data, extra: metadata })
  } catch {}
}
