import { UserRole } from '@quickcommerceltd/zipp'

export enum Permission {
  // ZIM
  CAN_SEE_PO = 'CAN_SEE_PO',
  CAN_EDIT_PO = 'CAN_EDIT_PO',
  CAN_SEE_LOG_EXPLORER = 'CAN_SEE_LOG_EXPLORER',
  CAN_SEE_STOCK_EXPLORER = 'CAN_SEE_STOCK_EXPLORER',
  CAN_SEE_DISCREPANCY_EXPLORER = 'CAN_SEE_DISCREPANCY_EXPLORER',
  CAN_SEE_LOCATIONS = 'CAN_SEE_LOCATIONS',
  CAN_EDIT_LOCATIONS = 'CAN_EDIT_LOCATIONS',
  CAN_SEE_SUPPLIERS = 'CAN_SEE_SUPPLIERS',
  CAN_SEE_USERS = 'CAN_SEE_USERS',
  CAN_CREATE_USERS = 'CAN_CREATE_USERS',

  // ZIPP
  CAN_PICK = 'CAN_PICK',
  CAN_INBOUND = 'CAN_INBOUND',
  CAN_PUTAWAY = 'CAN_PUTAWAY',
  CAN_ADJUST_STOCK = 'CAN_ADJUST_STOCK',
  CAN_MOVE_SKU = 'CAN_MOVE_SKU',
  CAN_CYCLE_COUNT = 'CAN_CYCLE_COUNT',
  CAN_SWEEP_DISCREPANCIES = 'CAN_SWEEP_DISCREPANCIES',
  CAN_SWEEP_EXPIRED_ITEMS = 'CAN_SWEEP_EXPIRED_ITEMS',
  CAN_CHANGE_STORE = 'CAN_CHANGE_STORE',
  CAN_SEE_STOCK_MOVEMENT_EXPLORER = 'CAN_SEE_STOCK_MOVEMENT_EXPLORER',
}

export const PERMISSIONS: Record<Permission, UserRole[]> = {
  // ZIM
  [Permission.CAN_SEE_PO]: [],
  [Permission.CAN_EDIT_PO]: [UserRole.ADMIN, UserRole.PROCUREMENT_ASSOCIATE, UserRole.SITE_MANAGER],
  [Permission.CAN_SEE_LOG_EXPLORER]: [],
  [Permission.CAN_SEE_STOCK_EXPLORER]: [],
  [Permission.CAN_SEE_DISCREPANCY_EXPLORER]: [UserRole.ADMIN, UserRole.SITE_MANAGER, UserRole.SHIFT_LEADER],
  [Permission.CAN_SEE_LOCATIONS]: [UserRole.ADMIN],
  [Permission.CAN_EDIT_LOCATIONS]: [UserRole.ADMIN],
  [Permission.CAN_SEE_SUPPLIERS]: [UserRole.ADMIN, UserRole.PROCUREMENT_ASSOCIATE],
  [Permission.CAN_SEE_USERS]: [],
  [Permission.CAN_CREATE_USERS]: [UserRole.ADMIN],

  // ZIPP
  [Permission.CAN_PICK]: [UserRole.ADMIN, UserRole.SITE_MANAGER, UserRole.SHIFT_LEADER, UserRole.SITE_ASSOCIATE],
  [Permission.CAN_INBOUND]: [UserRole.ADMIN, UserRole.SITE_MANAGER, UserRole.SHIFT_LEADER, UserRole.SITE_ASSOCIATE],
  [Permission.CAN_PUTAWAY]: [UserRole.ADMIN, UserRole.SITE_MANAGER, UserRole.SHIFT_LEADER, UserRole.SITE_ASSOCIATE],
  [Permission.CAN_ADJUST_STOCK]: [],
  [Permission.CAN_MOVE_SKU]: [UserRole.ADMIN, UserRole.SITE_MANAGER, UserRole.SHIFT_LEADER, UserRole.SITE_ASSOCIATE],
  [Permission.CAN_CYCLE_COUNT]: [UserRole.ADMIN, UserRole.SITE_MANAGER, UserRole.SHIFT_LEADER, UserRole.SITE_ASSOCIATE],
  [Permission.CAN_SWEEP_DISCREPANCIES]: [UserRole.ADMIN, UserRole.SITE_MANAGER, UserRole.SHIFT_LEADER],
  [Permission.CAN_SWEEP_EXPIRED_ITEMS]: [
    UserRole.ADMIN,
    UserRole.SITE_MANAGER,
    UserRole.SHIFT_LEADER,
    UserRole.SITE_ASSOCIATE,
  ],
  [Permission.CAN_CHANGE_STORE]: [],
  [Permission.CAN_SEE_STOCK_MOVEMENT_EXPLORER]: [],
}
