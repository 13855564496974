import { Box, Button, Typography } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { APP_VERSION } from '../../consts/version'
import { useAppConfig } from '../../hooks'

const SECONDS_TO_RELOAD = 3 * 60

export const VersionCheck: FC = () => {
  const [secondsLeft, setSecondsLeft] = useState(0)
  const minVersion = useAppConfig((state) => state.appConfig?.minVersion ?? APP_VERSION)

  const isSupportedVersion = useMemo(() => {
    const replaceLetters = (str: string) => str.replaceAll(/[^0-9.]+/g, '')
    const currentVersionArr = replaceLetters(APP_VERSION).split('.')
    const minVersionArr = replaceLetters(minVersion).split('.')

    for (let i = 0; i < currentVersionArr.length; i++) {
      const currentVersionPart = parseInt(currentVersionArr[i]) || 0
      const minVersionPart = parseInt(minVersionArr[i]) || 0
      if (currentVersionPart < minVersionPart) return false
    }

    return true
  }, [minVersion])

  useEffect(() => {
    if (isSupportedVersion) return

    setSecondsLeft(SECONDS_TO_RELOAD)
    const interval = setInterval(() => {
      setSecondsLeft((secondsLeft) => {
        if (secondsLeft <= 1) {
          clearInterval(interval)
          window.location.reload()
        }

        return secondsLeft - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [isSupportedVersion])

  if (isSupportedVersion) return null

  return (
    <Box
      position="sticky"
      bottom={0}
      left={0}
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="primary.main"
      color="common.white"
      p={2}
    >
      <Typography color="inherit" variant="body2" style={{ fontWeight: 'bold' }}>
        There is a new version of ZIM available. The page will reload in {secondsLeft} seconds.
      </Typography>
      <Button style={{ marginLeft: 2 }} variant="outlined" color="inherit" onClick={() => window.location.reload()}>
        Reload now
      </Button>
    </Box>
  )
}
