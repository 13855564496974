import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { AppProvider } from './providers'

Sentry.init({
  dsn: 'https://ae975a347431464aaa7facb6c4c79712@o552852.ingest.sentry.io/5679349',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_FIREBASE_CONFIG,

  // tracesSampleRate sets the percentage of events related to performance
  // this is intenionally set lower in production to reduce total transactions.
  // 1.0 === 100% | 0.1 === 10%
  tracesSampleRate: process.env.REACT_APP_FIREBASE_CONFIG === 'production' ? 0.1 : 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <AppProvider />
  </React.StrictMode>,
  document.getElementById('root')
)
