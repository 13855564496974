export enum LogCategory {
  AUTH = 'AUTH',
  PROFILE = 'PROFILE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  INBOUNDING = 'INBOUNDING',
  PUTAWAY = 'PUTAWAY',
  PICKING = 'PICKING',
  CYCLE_COUNT = 'CYCLE_COUNT',
  STOCK_ADJUSTMENT = 'STOCK_ADJUSTMENT',
  DISCREPANCY_SWEEPER = 'DISCREPANCY_SWEEPER',
  EXPIRY_SWEEPER = 'EXPIRY_SWEEPER',
  DEVICE = 'DEVICE',
  UNCAUGHT_ERROR = 'UNCAUGHT_ERROR',
}
