import { StockMovement } from '@quickcommerceltd/zipp'
import {
  collection,
  getDocs,
  limit,
  orderBy,
  OrderByDirection,
  query,
  Query,
  QueryConstraint,
  Timestamp,
  where,
} from 'firebase/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'
import { StockMovementQueryParams } from './types'

const converter = new GenericConverter<StockMovement>()

interface QueryParams {
  limit?: number
  orderBy?: string[][]
}

export const getStockMovementsQuery = (
  warehouseId: string | undefined,
  params: StockMovementQueryParams,
  queryParams?: QueryParams
) => {
  const args: QueryConstraint[] = []

  const { purchaseOrderId, state, expiryDate, sku, location, locations, expiryDates, lastUpdatedAtRange, allocatedBy } =
    params

  if (purchaseOrderId) {
    args.push(where('purchaseOrderId', '==', purchaseOrderId))
  }

  if (state) {
    Array.isArray(state) ? args.push(where('state', 'in', state)) : args.push(where('state', '==', state))
  }

  if (location) {
    args.push(where('locations', 'array-contains', location))
  }

  if (locations) {
    args.push(where('locations', 'array-contains-any', locations))
  }

  if (expiryDate) {
    args.push(where('expiryDate', '==', Timestamp.fromDate(new Date(expiryDate))))
  }

  if (expiryDates && !!expiryDates.length) {
    expiryDates.forEach((filter) => {
      const clause = where('expiryDate', filter.operator, Timestamp.fromDate(filter.value))

      args.push(clause)
    })
  }

  if (lastUpdatedAtRange && !!lastUpdatedAtRange.length) {
    lastUpdatedAtRange.forEach((filter) => {
      const clause = where('lastUpdatedAt', filter.operator, Timestamp.fromDate(filter.value))

      args.push(clause)
    })
  }

  if (sku) {
    args.push(where('sku', '==', sku))
  }

  if (allocatedBy) {
    typeof allocatedBy === 'string'
      ? args.push(where(`allocatedBy.${allocatedBy}`, '>', 0))
      : args.push(where('allocatedBy', '==', allocatedBy))
  }

  if (queryParams?.limit) {
    args.push(limit(queryParams.limit))
  }

  if (queryParams?.orderBy) {
    args.push(...queryParams.orderBy.map((params) => orderBy(params[0], params[1] as OrderByDirection)))
  }

  return query(collection(zimBackendDb, `warehouses/${warehouseId}/stockMovements`), ...args).withConverter(converter)
}

export const getStockMovements = async (stockMovementsQuery: Query<StockMovement>) => {
  const stockMovements = await getDocs<StockMovement>(stockMovementsQuery)
  return stockMovements.docs.map((doc) => doc.data())
}
