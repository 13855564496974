import { User } from '@quickcommerceltd/zipp'
import { httpsCallable } from 'firebase/functions'
import { zimFunctions } from '../../../firebase'

interface UpdateUserRequest {
  userId: string
  user: Omit<Partial<User>, 'id' | 'email' | 'createdAt' | 'updatedAt'>
}

export const updateUserCallable = httpsCallable<UpdateUserRequest, unknown>(zimFunctions, 'api-user-update')
