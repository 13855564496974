import { StockMovementState } from '@quickcommerceltd/zipp'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useSound from 'use-sound'
import { ROUTES } from '../../consts/Routes'
import { useMyDevice, useMyUser, useQueue, useStockMovements } from '../../hooks'

const NEW_ORDER_SOUND =
  'https://firebasestorage.googleapis.com/v0/b/project-corner.appspot.com/o/zoee-new-order-sound.mp3?alt=media&token=e1727c9d-5c0c-4a2f-bd4a-14c0c84a39db'

export const useTabbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { queue } = useQueue()
  const warehouseId = useMyUser((state) => state.user?.warehouseId)
  const deviceTask = useMyDevice((state) => state.device?.task)
  const [play, { stop }] = useSound(NEW_ORDER_SOUND, { loop: true })
  const [putawayStockMovements] = useStockMovements(warehouseId, { state: StockMovementState.INBOUNDED }, { limit: 1 })
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const toggleSidebar = useCallback(() => setIsSidebarOpen((prev) => !prev), [])

  const handleNavigationChange = useCallback(
    (_, path) => {
      if (!path) return
      navigate(path)
    },
    [navigate]
  )

  const currentPath = useMemo(() => {
    const rootPath = location.pathname.split('/')[1]
    return `/${rootPath}`
  }, [location])

  const isSilentPath = useMemo(() => {
    const currentPathSegments = location.pathname.split('/')

    return [ROUTES.PICKING_V2_PRODUCT_LIST, ROUTES.PICKING_V2_SUMMARY, ROUTES.PICKING_V3_PRODUCT_LIST].some((path) => {
      return path.split('/').every((segment, index) => {
        if (segment.startsWith(':')) return true
        return segment === currentPathSegments[index]
      })
    })
  }, [location])

  const isTaskWaiting = useMemo(
    () => !!queue?.isTaskWaiting || !!queue?.tasks?.length || !!deviceTask,
    [queue, deviceTask]
  )

  const isPutawayTaskWaiting = useMemo(() => {
    return !!putawayStockMovements?.length
  }, [putawayStockMovements])

  // play sound when task is waiting
  useEffect(() => {
    isTaskWaiting && !isSilentPath ? play() : stop()
    return () => stop()
  }, [isTaskWaiting, isSilentPath, play, stop])

  return {
    queue,
    currentPath,
    isSidebarOpen,
    isTaskWaiting,
    isPutawayTaskWaiting,
    handleNavigationChange,
    toggleSidebar,
  }
}
