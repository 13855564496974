export const ZappLogo = () => {
  return (
    <svg width="114" height="23" viewBox="0 0 114 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.3548 2.76044V0H0V3.38475H18.4379L0 20.2456V23.006H24.8789V19.6213H5.9169L24.3548 2.76044Z"
        fill="#002533"
      />
      <path
        d="M104.58 0H89.0352V23.006H92.979V15.0532H104.58C110.168 15.0532 113.881 12.3579 113.881 7.52658C113.881 2.69522 110.168 0 104.58 0ZM104.251 11.6684H92.979V3.4523H104.251C107.636 3.4523 109.84 4.60307 109.84 7.56152C109.84 10.52 107.638 11.6707 104.251 11.6707V11.6684Z"
        fill="#002533"
      />
      <path
        d="M54.4616 17.4525L52.6539 14.0981L45.0364 0H40.6989L28.2432 23.006H32.5154L35.4413 17.4502H41.4257L50.1963 17.4525L53.1547 23.006H57.4596L54.4616 17.4525ZM48.4561 14.1004H42.8351L37.214 14.0981L42.8351 3.44997L48.4561 14.0981V14.1004Z"
        fill="#002533"
      />
      <path
        d="M76.322 0H60.7773V23.006H64.7212V15.0532H76.322C81.9105 15.0532 85.6237 12.3579 85.6237 7.52658C85.6237 2.69522 81.9105 0 76.322 0ZM75.9936 11.6684H64.7212V3.4523H75.9936C79.3783 3.4523 81.582 4.60307 81.582 7.56152C81.582 10.52 79.3806 11.6707 75.9936 11.6707V11.6684Z"
        fill="#002533"
      />
    </svg>
  )
}
