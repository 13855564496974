import { POReview } from '@quickcommerceltd/zipp'
import { collection } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { firestoreDatesConverter } from '../../Containers/PurchaseOrders/PurchaseOrderDetailV3/_utils'
import { zimBackendDb } from '../../firebase'

export const usePurchaseOrderReviews = (purchaseOrderId: string): [reviews: POReview[], loadingReviews: boolean] => {
  const [reviews = [], loadingReviews] = useCollectionData<POReview>(
    collection(zimBackendDb, `purchaseOrders/${purchaseOrderId}/reviews`).withConverter(firestoreDatesConverter)
  )

  return [reviews, loadingReviews]
}
