import { ExpandMore } from '@mui/icons-material'
import { Alert, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import type { WarehouseConfig } from '@quickcommerceltd/zapp-app-utils'
import { useCallback, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useMyUser, useWarehouses } from '../../hooks'
export type { GridColumns as ZGridColumns } from '@mui/x-data-grid'

// TODO: migrate to emotion and styled
const useStyles = makeStyles()((theme) => ({
  root: {
    alignItems: 'center',
    lineHeight: '24px',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}))

interface Props {
  onChange(warehouseConfig: WarehouseConfig | ''): void
  warehouseId?: string
}
export const WarehousesSelectorComponent = (props: Props) => {
  const { classes } = useStyles()
  const warehouseId = useMyUser((state) => state.user?.warehouseId)
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(warehouseId)
  const [warehouses, , error] = useWarehouses()

  const handleOnChange = useCallback(
    (event: SelectChangeEvent<any>) => {
      const updatedWarehouseId = event.target.value

      if (!updatedWarehouseId) return

      const warehouse = warehouses.find((warehouse) => warehouse.id === updatedWarehouseId)

      if (!warehouse) return

      setSelectedWarehouseId(updatedWarehouseId)
      props.onChange({
        id: updatedWarehouseId,
        type: warehouse.type,
        shortName: warehouse.shortName,
        saleorWarehouseId: warehouse.saleorWarehouseId,
      } as WarehouseConfig)
    },
    [warehouses, props.onChange]
  )

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  return (
    <Select
      variant="outlined"
      displayEmpty
      disableUnderline
      value={selectedWarehouseId}
      onChange={handleOnChange}
      IconComponent={ExpandMore}
      classes={{ icon: classes.icon }}
    >
      <MenuItem value={''} disabled>
        Select a Warehouse
      </MenuItem>
      {warehouses.map((warehouse) => (
        <MenuItem key={warehouse.id} value={warehouse.id}>
          {warehouse.shortName} - {warehouse.id}
        </MenuItem>
      ))}
    </Select>
  )
}
