import { logEvent } from 'firebase/analytics'
import { useEffect } from 'react'
import { zimAnalytics } from '../../firebase'

export const useTrackPageTime = (eventName: string, metadata = {}) => {
  const startTime = Date.now()

  useEffect(() => {
    return () => {
      if (!startTime) return
      const endTime = Date.now()
      const timeSpent = endTime - startTime

      logEvent(zimAnalytics, eventName, {
        durationInSecs: timeSpent / 1000,
        ...metadata,
      })
    }
  }, [])
}
