/* eslint-disable import/order */
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { getRemoteConfig } from 'firebase/remote-config'
import { getStorage } from 'firebase/storage'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

import { ENVIRONMENT } from '../consts/ENVIRONMENT'

// ZIM/ZIPP App
const zimBackend = firebase.initializeApp(ENVIRONMENT.firebaseConfig)
const zimAnalytics = getAnalytics(zimBackend)
const zimFunctions = getFunctions(zimBackend, ENVIRONMENT.appRegion)
const zimBackendAuth = getAuth(zimBackend)
const zimBackendStorage = getStorage(zimBackend)
const zimBackendDb = zimBackend.firestore()
zimBackendDb.settings({ ignoreUndefinedProperties: true, merge: true })
const zimBackendRemoteConfig = getRemoteConfig(zimBackend)
zimBackendRemoteConfig.settings.minimumFetchIntervalMillis = 600_000 // 10 minutes

export { firebase, zimAnalytics, zimBackendDb, zimFunctions, zimBackendRemoteConfig, zimBackendAuth, zimBackendStorage }
