import { ORDER_PREFIXES } from '../../PickingSuccess/constants'
import { ORDER_PREFIX } from '../../types'

const knownPrefixes: { [key: string]: ORDER_PREFIX } = {
  CO: ORDER_PREFIXES.CUSTOMER_ORDER,
  PO: ORDER_PREFIXES.PURCHASE_ORDER,
}

export const getOrderIdPrefix = (id: string) => {
  const prefix = `${id[0]}${id[1]}`.toUpperCase() as ORDER_PREFIX

  return knownPrefixes[prefix]
}
