import { httpsCallable } from '@firebase/functions'
import { User } from '@quickcommerceltd/zipp'
import { zimFunctions } from '../../../firebase'

interface LogRequest {
  warehouseId: string
  category: string
  action: string
  message: string
  data: Record<string, unknown>
  user: User | undefined
  metadata: Record<string, unknown>
  createdAt: Date
}

export const logCallable = httpsCallable<LogRequest, unknown>(zimFunctions, 'log')
