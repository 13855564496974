import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { App } from '../App'
import { AppInit } from '../AppInit'
import CommonProviders from '../Components/CommonProviders/CommonProviders'
import ErrorBoundary from '../ErrorBoundary'

export const AppProvider: FC = () => {
  return (
    <ErrorBoundary>
      <Helmet>
        <title>ZIM / ZIPP</title>
      </Helmet>
      <CommonProviders>
        <AppInit />
        <App />
      </CommonProviders>
    </ErrorBoundary>
  )
}
