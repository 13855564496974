import { CustomerOrder } from '@quickcommerceltd/zipp'
import { useMemo } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { getOrderRef } from '../../Containers/PickingV2/PickingProductList/queries'
import { GenericDataConverter } from '../../firebase/genericConverter'

const customerOrderConverter = new GenericDataConverter<CustomerOrder>()

export const useCustomerOrder = (orderId: string) => {
  const ref = useMemo(() => {
    if (!orderId) return undefined
    return getOrderRef(orderId).withConverter(customerOrderConverter)
  }, [orderId])

  return useDocumentData<CustomerOrder>(ref)
}
