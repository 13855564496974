import { collection, query, QueryConstraint, where } from 'firebase/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'
import { Log } from './types'

const converter = new GenericConverter<Log>()

export const getLogQuery = (warehouseId: string, params: Partial<Log>) => {
  const { purchaseOrderId, action } = params
  const args: QueryConstraint[] = []

  if (purchaseOrderId) {
    args.push(where('purchaseOrderId', '==', purchaseOrderId))
  }

  if (action) {
    args.push(where('action', '==', action))
  }

  return query(collection(zimBackendDb, `warehouses/${warehouseId}/log`), ...args).withConverter(converter)
}
