import { LogCategory } from './LogCategory'

export enum LogAction {
  LOGIN = 'AUTH_LOGIN',
  LOGOUT = 'AUTH_LOGOUT',
  SIGNUP_COMPLETE = 'AUTH_SIGNUP_COMPLETE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  INBOUND = 'INBOUND',
  VALIDATE_LOCATION = 'VALIDATE_LOCATION',
  PUTAWAY = 'PUTAWAY',
  ACCEPT_TASK = 'ACCEPT_TASK',
  FETCH_PICKLIST = 'FETCH_PICKLIST',
  PICK = 'PICK',
  FINALIZE_TASK = 'FINALIZE_TASK',
  CONFIRM = 'CONFIRM',
  UNCAUGHT_ERROR = 'UNCAUGHT_ERROR',
}

const logCategoryActionsMap = {
  [LogCategory.AUTH]: [LogAction.LOGIN, LogAction.LOGOUT, LogAction.SIGNUP_COMPLETE],
  [LogCategory.PROFILE]: [LogAction.UPDATE],
  [LogCategory.PURCHASE_ORDER]: [LogAction.CREATE, LogAction.UPDATE],
  [LogCategory.INBOUNDING]: [LogAction.INBOUND],
  [LogCategory.PICKING]: [LogAction.ACCEPT_TASK, LogAction.FETCH_PICKLIST, LogAction.PICK, LogAction.FINALIZE_TASK],
  [LogCategory.PUTAWAY]: [LogAction.VALIDATE_LOCATION, LogAction.PUTAWAY],
  [LogCategory.CYCLE_COUNT]: [],
  [LogCategory.STOCK_ADJUSTMENT]: [],
  [LogCategory.DISCREPANCY_SWEEPER]: [LogAction.CONFIRM],
  [LogCategory.EXPIRY_SWEEPER]: [],
  [LogCategory.DEVICE]: [LogAction.UPDATE],
  [LogCategory.UNCAUGHT_ERROR]: [LogAction.UNCAUGHT_ERROR],
} as const

export type LogActionsMap = {
  [K in keyof typeof logCategoryActionsMap]: (typeof logCategoryActionsMap)[K][number]
}
