import { Warehouse } from '@quickcommerceltd/zipp'
import { collection, FirestoreError, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericConverter'

const warehouseConverter = new GenericDataConverter<Warehouse>()

interface Props {
  all?: boolean
}

export const useWarehouses = ({ all }: Props = {}): [Warehouse[], boolean, FirestoreError | undefined] => {
  const warehouseQuery = useMemo(() => {
    return query(collection(zimBackendDb, 'warehouses'), ...(all ? [] : [where('isDraft', '==', false)])).withConverter(
      warehouseConverter
    )
  }, [all])

  const [warehouses = [], loading, error] = useCollectionDataOnce(warehouseQuery)

  return [warehouses, loading, error]
}
