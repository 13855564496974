import { SvgIcon, SvgIconProps } from '@mui/material'

export const FrameInspectIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props} width="15" height="16" viewBox="0 0 15 16" fill={props.fill}>
    <path
      d="M6.875 9.25C7.40278 9.25 7.84722 9.06944 8.20833 8.70833C8.56944 8.34722 8.75 7.90278 8.75 7.375C8.75 6.84722 8.56944 6.40278 8.20833 6.04167C7.84722 5.68056 7.40278 5.5 6.875 5.5C6.34722 5.5 5.90278 5.68056 5.54167 6.04167C5.18056 6.40278 5 6.84722 5 7.375C5 7.90278 5.18056 8.34722 5.54167 8.70833C5.90278 9.06944 6.34722 9.25 6.875 9.25ZM10.8958 12.5833L8.70833 10.3958C8.43056 10.5764 8.13542 10.7083 7.82292 10.7917C7.51042 10.875 7.19444 10.9167 6.875 10.9167C5.88889 10.9167 5.05208 10.5729 4.36458 9.88542C3.67708 9.19792 3.33333 8.36111 3.33333 7.375C3.33333 6.38889 3.67708 5.55208 4.36458 4.86458C5.05208 4.17708 5.88889 3.83333 6.875 3.83333C7.86111 3.83333 8.69792 4.17708 9.38542 4.86458C10.0729 5.55208 10.4167 6.38889 10.4167 7.375C10.4167 7.69444 10.3715 8.01042 10.2812 8.32292C10.191 8.63542 10.0556 8.93056 9.875 9.20833L12.0833 11.4167L10.8958 12.5833ZM1.66667 15.5C1.20833 15.5 0.815972 15.3368 0.489583 15.0104C0.163194 14.684 0 14.2917 0 13.8333V10.5H1.66667V13.8333H5V15.5H1.66667ZM10 15.5V13.8333H13.3333V10.5H15V13.8333C15 14.2917 14.8368 14.684 14.5104 15.0104C14.184 15.3368 13.7917 15.5 13.3333 15.5H10ZM0 5.5V2.16667C0 1.70833 0.163194 1.31597 0.489583 0.989583C0.815972 0.663194 1.20833 0.5 1.66667 0.5H5V2.16667H1.66667V5.5H0ZM13.3333 5.5V2.16667H10V0.5H13.3333C13.7917 0.5 14.184 0.663194 14.5104 0.989583C14.8368 1.31597 15 1.70833 15 2.16667V5.5H13.3333Z"
      fill="currentColor"
    />
  </SvgIcon>
)
