import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)

  const checkConnection = useCallback(() => {
    axios
      .get(`/no-cache.png?v=${Date.now()}`, { timeout: 9500 })
      .then(() => setIsOnline(true))
      .catch(() => setIsOnline(false))
  }, [])

  useEffect(() => {
    const interval = setInterval(checkConnection, 10000)
    const onOffline = () => setIsOnline(false)
    const onOnline = () => checkConnection()

    checkConnection()
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)

    return () => {
      clearInterval(interval)
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [checkConnection])

  return isOnline
}
