import { Provider as FeatureFlagProvider } from '../../Containers/FeatureFlag'
import ToastProvider from '../../store/toast/ToastProvider'
import { ThemeProvider } from '../Theme'
import ToggleUTCProvider from './ToggleUTCProvider'

const CommonProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider>
      <FeatureFlagProvider>
        <ToggleUTCProvider>
          <ToastProvider>{children}</ToastProvider>
        </ToggleUTCProvider>
      </FeatureFlagProvider>
    </ThemeProvider>
  )
}

export default CommonProviders
