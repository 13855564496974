import { Temperature } from '@quickcommerceltd/zipp'
import { PicklistProduct } from '../../types'
import { ProductStates } from '../constants'
import { PickingProductListFilter } from '../types'

type Predicate<T> = (value: T) => boolean
type ProductPredicate = Predicate<PicklistProduct>

const productStatePredicates: Partial<Record<ProductStates, ProductPredicate>> = {
  [ProductStates.PICKED]: ({ quantityPicked, quantityToPick }) => quantityPicked === quantityToPick,
  [ProductStates.NOT_PICKED]: ({ quantityPicked }) => !quantityPicked,
  [ProductStates.PARTIALLY_PICKED]: ({ quantityPicked, quantityToPick }) =>
    quantityPicked > 0 && quantityPicked < quantityToPick,
}

type ProductTemperatureKind = 'temperatureStorage' | 'temperatureFulfillment'

const createTemperaturePredicate =
  (kind: ProductTemperatureKind, temperature: Temperature): ProductPredicate =>
  (product) => {
    if (temperature === Temperature.AMBIENT) {
      return [Temperature.AMBIENT, undefined].includes(product[kind])
    }

    return product[kind] === temperature
  }

const isAPredicate = (value: ProductPredicate | undefined): value is ProductPredicate => !!value

export const getFilteredProducts = (products: PicklistProduct[], filters: PickingProductListFilter) => {
  const { productState, temperatureFulfillment, temperatureStorage = '' } = filters

  const predicates: ProductPredicate[] = [
    productStatePredicates[productState],
    temperatureFulfillment ? createTemperaturePredicate('temperatureFulfillment', temperatureFulfillment) : undefined,
    temperatureStorage ? createTemperaturePredicate('temperatureStorage', temperatureStorage) : undefined,
  ].filter(isAPredicate)

  if (!predicates.length) return products

  const matchesAllPredicates = (product: PicklistProduct) => predicates.every((predicate) => predicate(product))

  return products.filter(matchesAllPredicates)
}
