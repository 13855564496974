import { PurchaseOrder } from '@quickcommerceltd/zipp'
import { doc } from 'firebase/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'
import { GetPurchaseOrderParams } from './types'

const converter = new GenericConverter<PurchaseOrder>()

export const getPurchaseOrderRef = (params: GetPurchaseOrderParams) => {
  const { id } = params

  return doc(zimBackendDb, `purchaseOrders/${id}`).withConverter(converter)
}
