import { Typography } from '@mui/material'
import { CSSProperties, memo } from 'react'
import { APP_VERSION } from '../../consts/version'
import { isDevEnv } from '../../utils/env'

interface StatusBarProps {
  email: string
  shortName: string
}

export const StatusBar = memo<StatusBarProps>(({ email, shortName }) => {
  const styles = {
    fontSize: '12px',
    textAlign: 'center',
    width: '100%',
    zIndex: 11,
    bottom: 0,
  } as CSSProperties

  const ENV = isDevEnv ? process.env.REACT_APP_FIREBASE_CONFIG : undefined

  const label = [ENV, email.split('@').shift(), shortName, APP_VERSION].filter((s) => !!s).join(' • ')

  return (
    <Typography component="span" data-testid="status-bar" style={styles}>
      {label}
    </Typography>
  )
})
