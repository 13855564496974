import { PurchaseOrderProduct } from '@quickcommerceltd/zipp'
import { PurchaseOrderCsvData } from '../_types'

export const generatePurchaseOrderCsvData = (
  purchaseOrderProducts: PurchaseOrderProduct[] | undefined
): PurchaseOrderCsvData[] => {
  return (
    purchaseOrderProducts?.map((product) => ({
      title: product.title,
      id: product.id,
      supplierSKU: product.supplierSKU,
      barcodes: product.barcodes,
      packSize: product.packSize,
      packs: product.packs,
      quantity: product.quantity,
      costBase: product.costBase / 100,
      costTotal: product.costTotal / 100,
      quantityPicked: product.quantityPicked,
      quantityInbounded: product.quantityInbounded,
      quantityPutaway: product.quantityPutaway,
      canExpire: product.canExpire,
      temperatureStorage: product.temperatureStorage,
      temperatureFulfillment: product.temperatureFulfillment,
      supplierSLA: product.supplierSLA,
      customerSLA: product.customerSLA,
    })) ?? []
  )
}
