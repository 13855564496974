import { PickTask } from '@quickcommerceltd/zipp'
import { doc } from 'firebase/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'

const pickTaskConverter = new GenericConverter<PickTask>()
export const getDeviceTaskRef = (id: string) => {
  const path = `tasks/${id}`

  return doc(zimBackendDb, path).withConverter(pickTaskConverter)
}
