import { signOut } from 'firebase/auth'
import { setDeviceStatus } from '../utils/deviceStatus'
import { LogAction, LogCategory, log } from '../utils/logHelper'
import { zimBackendAuth } from './'

export const useLogout = () => {
  const logout = async () => {
    const authUser = zimBackendAuth.currentUser

    await Promise.allSettled([
      signOut(zimBackendAuth),
      setDeviceStatus({
        isOnline: false,
        pushToken: null,
      }),
    ])

    log(LogCategory.AUTH, LogAction.LOGOUT, 'Logged out successfully', {
      id: authUser?.uid,
      email: authUser?.email,
    })
  }

  return {
    logout,
  }
}
