import { CatalogProduct } from '@quickcommerceltd/zipp'
import { collection, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericConverter'

const converter = new GenericDataConverter<CatalogProduct>()

interface Params {
  id?: string
  once?: boolean
}

export const useCatalogProduct = (params: Params) => {
  const { id, once } = params

  const useCollection = useMemo(() => {
    return once ? useCollectionDataOnce : useCollectionData
  }, [once])

  const [catalogProducts, ...rest] = useCollection<CatalogProduct>(
    id ? query(collection(zimBackendDb, `catalogProducts`), where('id', '==', id)).withConverter(converter) : null
  )

  return [catalogProducts?.[0], ...rest] as const
}
