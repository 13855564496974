import { collection, query } from 'firebase/firestore'
import { getOrderPath } from '../../Containers/PickingV2/PickingProductList/queries'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'
import { PurchaseOrderPicklistProduct } from './types'

const converter = new GenericConverter<PurchaseOrderPicklistProduct>()

export const getPurchaseOrderPicklistQuery = (purchaseOrderId: string) => {
  const path = `${getOrderPath(purchaseOrderId)}/picklist`

  return query(collection(zimBackendDb, path)).withConverter(converter)
}
