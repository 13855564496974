import { FirestoreError, QuerySnapshot } from 'firebase/firestore'

export enum ActionType {
  LOADING = 'loading',
  LOADED = 'loaded',
  LOAD_MORE = 'loadMore',
  ERROR = 'error',
  RESET = 'reset',
}

type LoadingAction = { type: ActionType.LOADING }
export const loadingAction = (): LoadingAction => ({
  type: ActionType.LOADING,
})

interface LoadedActionPayload {
  snapshot: QuerySnapshot
  pageSize: number
}
type LoadedAction = { type: ActionType.LOADED; payload: LoadedActionPayload }
export const loadedAction = (payload: LoadedActionPayload): LoadedAction => ({
  type: ActionType.LOADED,
  payload,
})

type LoadMoreAction = { type: ActionType.LOAD_MORE }
export const loadMoreAction = (): LoadMoreAction => ({
  type: ActionType.LOAD_MORE,
})

type ErrorAction = {
  type: ActionType.ERROR
  payload: FirestoreError
}
export const errorAction = (error: FirestoreError): ErrorAction => ({
  type: ActionType.ERROR,
  payload: error,
})

type ResetAction = { type: ActionType.RESET }
export const resetAction = (): ResetAction => ({
  type: ActionType.RESET,
})

export type Action = LoadingAction | LoadedAction | LoadMoreAction | ErrorAction | ResetAction | LoadedAction
