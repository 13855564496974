import { PurchaseOrderPicklistProduct } from '../../../../db/purchaseOrderPicklist/types'

export const getUnpickableQuantity = (
  picklist: PurchaseOrderPicklistProduct[],
  location: string,
  containerId: string,
  expiryDate: string
) => {
  const product = picklist.find(
    (picklistItem) =>
      picklistItem.to === containerId &&
      picklistItem.from === location &&
      picklistItem.expiryDate.toDate().toISOString() === expiryDate
  )

  return product?.quantity || 0
}
