import { create } from 'zustand'

interface AuthInitState {
  isInitializing: boolean
  isLoggedIn: boolean
  setIsInitializing: (isInitializing: boolean) => void
  setIsLoggedIn: (isLoggedIn: boolean) => void
}

export const useAuthInit = create<AuthInitState>((set) => ({
  isInitializing: true,
  isLoggedIn: false,
  setIsInitializing: (isInitializing) => set({ isInitializing }),
  setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
}))
