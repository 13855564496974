import { firebase, zimBackendAuth, zimBackendDb } from '../firebase'
import { getDeviceId } from './dataStore'
import { LogAction, LogCategory, log, logSentryError } from './logHelper'

declare global {
  interface Window {
    pushToken: string
  }
}

const COLLECTION_DEVICES = 'devices'

interface Props {
  isOnline: boolean
  pushToken?: string | null
  userId?: string
  userEmail?: string
  userName?: string
  warehouseId?: string
}

export const setDeviceStatus = async ({ isOnline, pushToken, userId, userEmail, userName, warehouseId }: Props) => {
  if (!zimBackendAuth?.currentUser) {
    return
  }

  const lastSeen = firebase.firestore.FieldValue.serverTimestamp()
  const id = getDeviceId()
  const device = {
    id,
    userEmail,
    userName,
    userId,
    warehouseId,
    pushToken,
    lastSeen,
    isOnline,
  }

  try {
    await zimBackendDb.collection(COLLECTION_DEVICES).doc(device.id).set(device, { merge: true })
  } catch (error) {
    logSentryError(error)
    log(LogCategory.DEVICE, LogAction.UPDATE, 'Failed to update deviceStatus', { error, device })
  }
}
