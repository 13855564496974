import { PicklistProduct } from '../../types'
import { LocationPicklistMap, PicklistProductMap } from '../types'

export const mergeProducts = (picklistProductMap: PicklistProductMap, pickedItemsMap: LocationPicklistMap) => {
  return Object.entries(picklistProductMap).reduce((acc: PicklistProduct[], [mapKey, picklistProduct]) => {
    const { sku, quantity } = pickedItemsMap[mapKey] || {}
    const isSameProduct = sku === picklistProduct.id
    const newProduct = { ...picklistProduct }

    if (isSameProduct) {
      newProduct.quantityPicked = quantity
      newProduct.quantityToPickRemaining = newProduct.quantityToPick - quantity
    } else {
      newProduct.quantityPicked = 0
      newProduct.quantityToPickRemaining = newProduct.quantityToPick
    }

    acc.push(newProduct)

    return acc
  }, [])
}
