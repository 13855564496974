import { useCallback, useEffect, useRef } from 'react'
import { BARCODE_REGEX, CONTAINER_REGEX, LOCATION_REGEX, SKU_REGEX } from '../../utils/constants'

type Type = 'SKU' | 'BARCODE' | 'LOCATION' | 'UNKNOWN'

interface Props {
  disabled?: boolean
  onScan: (type: Type, value: string) => void
}

export const useScanner = ({ disabled, onScan }: Props) => {
  const scanBuffer = useRef('')

  const getType = useCallback((value: string): Type => {
    if (SKU_REGEX.test(value.toUpperCase())) {
      return 'SKU'
    }

    if (LOCATION_REGEX.test(value) || CONTAINER_REGEX.test(value.toUpperCase())) {
      return 'LOCATION'
    }

    if (BARCODE_REGEX.test(value)) {
      return 'BARCODE'
    }

    return 'UNKNOWN'
  }, [])

  const onKeyPressEvent = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Enter') {
        scanBuffer.current += e.key
      }

      // reset buffer after scan completes
      if (e.key === 'Enter') {
        onScan(getType(scanBuffer.current), scanBuffer.current.toUpperCase())
        scanBuffer.current = ''
      }
    },
    [onScan]
  )

  useEffect(() => {
    if (disabled) return
    window.addEventListener('keypress', onKeyPressEvent)
    return () => {
      window.removeEventListener('keypress', onKeyPressEvent)
    }
  }, [disabled, onKeyPressEvent])
}
