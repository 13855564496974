import { SvgIcon, SvgIconProps } from '@mui/material'

export const UpdateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill={props.fill || 'black'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.044 10.675c0 4.87 3.531 8.19 7.498 8.876a.833.833 0 1 1-.285 1.643C4.599 20.387.377 16.46.377 10.675c0-2.459 1.118-4.392 2.424-5.86.936-1.053 2.006-1.91 2.867-2.564H3.051a.8.8 0 0 1 0-1.6h4.8a.8.8 0 0 1 .8.8v4.8a.8.8 0 1 1-1.6 0V3.298l-.002.003c-.915.688-2.032 1.531-3 2.62-1.144 1.287-2.005 2.842-2.005 4.753v.001Zm17.773.65c0-4.82-3.456-8.117-7.371-8.853a.834.834 0 1 1 .307-1.638c4.598.864 8.731 4.768 8.731 10.491 0 2.46-1.118 4.39-2.424 5.86-.936 1.054-2.006 1.91-2.867 2.564h2.618a.8.8 0 0 1 0 1.6h-4.8a.8.8 0 0 1-.8-.8v-4.8a.8.8 0 1 1 1.6 0v2.952h.003c.913-.691 2.032-1.533 2.998-2.624 1.144-1.285 2.005-2.84 2.005-4.752Z"
      />
    </SvgIcon>
  )
}
