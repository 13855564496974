import React from 'react'
import FullScreenSpinner from '../../Components/FullScreenSpinner'
import { REQUEST_STATUS } from '../../consts/requestStatus'
import FeatureFlagContext from './context'
import useProvider from './useProvider'

export interface Props {
  children?: React.ReactNode
}

const messages: Record<string, string> = {
  [REQUEST_STATUS.REJECTED]: 'Unable to fetch feature flags',
  [REQUEST_STATUS.PENDING]: 'Loading...',
}

const Provider = ({ children }: Props) => {
  const { fetchFeatureFlagsRequestStatus, fetchFeatureFlags, value } = useProvider()

  return (
    <FeatureFlagContext.Provider value={value}>
      <FullScreenSpinner
        requestStatus={fetchFeatureFlagsRequestStatus}
        message={messages[fetchFeatureFlagsRequestStatus]}
        onTryAgain={fetchFeatureFlags}
      >
        {children}
      </FullScreenSpinner>
    </FeatureFlagContext.Provider>
  )
}

export default Provider
