import { v4 } from 'uuid'

const KEYS = {
  DEVICE_ID: 'deviceId',
}

const ensureDeviceId = (): string => {
  let deviceId = localStorage.getItem(KEYS.DEVICE_ID)

  if (!deviceId) {
    deviceId = v4()
    localStorage.setItem(KEYS.DEVICE_ID, deviceId)
  }

  return deviceId
}

const deviceId = ensureDeviceId()
export const getDeviceId = () => deviceId
