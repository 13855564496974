import { collection, query } from 'firebase/firestore'
import { getOrderPath } from '../../Containers/PickingV2/PickingProductList/queries'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'
import { PurchaseOrderProduct } from './types'

const converter = new GenericConverter<PurchaseOrderProduct>()

export const getPurchaseOrderProductsQuery = (purchaseOrderId: string) => {
  const path = `${getOrderPath(purchaseOrderId)}/products`

  return query(collection(zimBackendDb, path)).withConverter(converter)
}
