import { PicklistProduct } from '../../types'

export const getLocationIndexesToPickFrom = (barcode: string, products: PicklistProduct[]) => {
  return products.reduce((acc: number[], product, index) => {
    const isSameSku = product.id === barcode
    const isSameBarcode = product?.barcodes?.includes(barcode)
    const isSameCaseBarcode = product?.caseBarcodes?.includes(barcode)

    if ((isSameSku || isSameBarcode || isSameCaseBarcode) && !!product.quantityToPickRemaining) {
      acc.push(index)
    }

    return acc
  }, [])
}
