import { FC, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { zimBackendAuth } from './firebase'
import { useAppConfig, useAuthInit, useMyUser, useMyWarehouse } from './hooks'
import { useMyDevice } from './hooks/useMyDevice/useMyDevice'

export const AppInit: FC = () => {
  const [authState, isInitializingAuthState] = useAuthState(zimBackendAuth)
  const isAppConfigInitializing = useAppConfig((state) => state.isInitializing)

  // user
  const userId = authState?.uid
  const isUserInitializing = useMyUser((state) => state.isInitializing)
  const isUserLoaded = useMyUser((state) => !!state.user?.id)

  // warehouse
  const warehouseId = useMyUser((state) => state.user?.warehouseId)
  const isWarehouseInitializing = useMyWarehouse((state) => state.isInitializing)

  // device
  const isDeviceInitializing = useMyDevice((state) => state.isInitializing)

  // login state
  const isLoggedIn = !!authState && !!isUserLoaded

  // subscribe to app config
  useEffect(() => {
    useAppConfig.getState().subscribe()

    return () => {
      useAppConfig.getState().unsubscribe?.()
    }
  }, [])

  // subscribe to user
  useEffect(() => {
    if (userId) {
      useMyUser.getState().subscribe(userId)
    }

    return () => {
      useMyUser.getState().unsubscribe?.()
    }
  }, [userId])

  // subscribe to users warehouse
  useEffect(() => {
    if (warehouseId) {
      useMyWarehouse.getState().subscribe(warehouseId)
    }

    return () => {
      useMyWarehouse.getState().unsubscribe?.()
    }
  }, [warehouseId])

  // subscribe to device
  useEffect(() => {
    if (userId) {
      useMyDevice.getState().subscribe()
    }

    return () => {
      useMyDevice.getState().unsubscribe?.()
      useMyDevice.getState().stopHeartbeat?.()
    }
  }, [userId])

  const isInitializing =
    isInitializingAuthState ||
    isAppConfigInitializing ||
    (userId ? isUserInitializing : false) ||
    (warehouseId ? isWarehouseInitializing : false) ||
    (userId ? isDeviceInitializing : false)

  // set app state
  useEffect(() => {
    useAuthInit.getState().setIsInitializing(isInitializing)
  }, [isInitializing])

  useEffect(() => {
    useAuthInit.getState().setIsLoggedIn(isLoggedIn)

    isLoggedIn ? useMyDevice.getState().startHeartbeat() : useMyDevice.getState().stopHeartbeat?.()
  }, [isLoggedIn])

  return null
}
