import { Alert, Backdrop } from '@mui/material'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useIsOnline } from './useIsOnline'

// TODO: migrate to emotion and styled
const useStyles = makeStyles()(() => ({
  root: {
    position: 'absolute',
    zIndex: 1000,
  },
}))

const InternetCheck: FC = () => {
  const { classes } = useStyles()
  const isOnline = useIsOnline()

  if (isOnline) return null

  return (
    <Backdrop open={true} className={classes.root}>
      <Alert severity="error">
        <strong>No Internet: </strong>Please check your internet connection
      </Alert>
    </Backdrop>
  )
}

export default InternetCheck
