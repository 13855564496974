import type { FeatureFlag, FeatureFlagMap } from './types'

export const getLocalStorageFeatureFlagValue = (flagName: string) => {
  return JSON.parse(localStorage.getItem('__ZAPP_FEATURE_FLAGS__') || '{}')?.[flagName]
}

export const getFeatureFlagValues = (flags: string[], warehouseId: string, config: FeatureFlagMap) => {
  return flags.reduce((acc: FeatureFlag, flagName) => {
    const flag = config[flagName]
    const valueForWarehouse = flag?.[warehouseId]
    const valueForAll = flag?.all
    const valueInLocalStorage = getLocalStorageFeatureFlagValue(flagName)

    acc[flagName] = valueInLocalStorage ?? valueForWarehouse ?? valueForAll ?? false

    return acc
  }, {})
}
