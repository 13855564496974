import { Stack } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import { TABBAR_HEIGHT } from '../Tabbar/Tabbar'
import { useTabbarConfig } from '../Tabbar/useTabbarConfig'

const DEFAULT_PADDING = 8

export interface PageProps {
  header?: ReactNode
  footer?: ReactNode
  showFooter?: boolean
  noPadding?: boolean
  contentWrapperStyle?: React.CSSProperties
  contentSx?: React.CSSProperties
}

const Page: FC<PageProps> = (props) => {
  const { header, footer, showFooter, children, contentSx } = props
  const isTabbarHidden = useTabbarConfig((state) => state.isHidden)

  return (
    <Stack height="100%">
      {header}

      <Stack p={props.noPadding ? 0 : 1} pb={0} flexGrow={1} sx={contentSx}>
        {children}
      </Stack>

      {showFooter && (
        <Stack
          width="100%"
          px={1}
          sx={{
            position: 'sticky',
            bottom: isTabbarHidden ? DEFAULT_PADDING : TABBAR_HEIGHT,
          }}
        >
          {footer}
        </Stack>
      )}
    </Stack>
  )
}

export default Page
