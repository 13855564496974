import { FirestoreError, getDocs, limit, onSnapshot, Query, query, QuerySnapshot, startAfter } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { errorAction, initialState, loadedAction, loadingAction, loadMoreAction, reducer, resetAction } from './_utils'

const DEFAULT_PAGE_SIZE = 20

interface PaginatedCollectionOptions {
  pageSize: number
  subscribeToAllPages?: boolean
}

interface PaginatedCollectionReturn {
  loading: boolean
  error: Error | null
  hasMoreElements: boolean
  loadMore: () => void
}

export const usePaginatedCollection = <T>(
  searchQuery: Query | undefined | null,
  options?: PaginatedCollectionOptions
): [T[], PaginatedCollectionReturn] => {
  const queryRef = useRef(searchQuery)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [subscriptions, setSubscriptions] = useState<Function[]>([])

  const cleanupSubscriptions = () => {
    subscriptions.forEach((unsubscribe: Function) => {
      unsubscribe()
    })
    setSubscriptions([])
  }

  // reset on query change
  useEffect(() => {
    if (!searchQuery) return
    queryRef.current = searchQuery
    dispatch(resetAction())
    cleanupSubscriptions()
  }, [searchQuery])

  // cleanup function that unsubscribes from
  // all open subscriptions on unmount
  useEffect(() => {
    return cleanupSubscriptions
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // function to execute query
  useEffect(() => {
    if (!queryRef.current) return

    // use pagesize for pagination
    const pageSize = options?.pageSize || DEFAULT_PAGE_SIZE
    let q = query(queryRef.current, limit(pageSize)) //.limit(pageSize)

    // use after cursor for pagination
    if (!!state.after) {
      q = query(q, startAfter(state.after))
    }

    // function called when documents were loaded successfully
    const onSuccess = (snapshot: QuerySnapshot) => {
      dispatch(loadedAction({ snapshot, pageSize }))
    }

    // function called when firestore query fails
    const onError = (error: FirestoreError) => {
      dispatch(errorAction(error))
    }

    // load following pages without a subscription
    if (!!state.after && !options?.subscribeToAllPages) {
      dispatch(loadingAction())
      getDocs(q).then(onSuccess)
      return
    }

    // subscribe to query documents
    dispatch(loadingAction())
    const subscription = onSnapshot(q, onSuccess, onError)
    setSubscriptions((subs) => [...subs, subscription])
  }, [queryRef.current, state.after]) // eslint-disable-line react-hooks/exhaustive-deps

  // get data for all documents
  const elements = useMemo(() => {
    return state.elements.map((doc) => doc.data() as T)
  }, [state.elements])

  // function to load more documents
  const loadMore = useCallback(() => {
    if (!state.hasMoreElements) return
    return dispatch(loadMoreAction())
  }, [state.hasMoreElements])

  return [
    elements,
    {
      loading: state.loading,
      error: state.error,
      hasMoreElements: state.hasMoreElements,
      loadMore,
    },
  ]
}
