import { Alert, Box, Divider, TextField, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import LoadingButton from '../../Components/LoadingButton'
import { Logo } from '../../Components/Svg/Logo'
import { LogoMobile } from '../../Components/Svg/LogoMobile'
import { useLogin } from '../../firebase/useLogin'

// TODO: migrate to emotion and styled
const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loginContent: {
    background: '#ffffff',
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    width: '100%',
    justifyContent: 'space-between',
    gap: '16px',
  },
  select: {
    border: '1px solid #c4c4c4',
    padding: '11px',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
  },
  divider: {
    width: '100%',
  },
  dividerText: {
    background: 'white',
    padding: '0 4px',
    marginTop: '-12px',
    color: 'grey',
  },
}))

const Login = () => {
  const { classes } = useStyles()
  const {
    isSmallScreen,
    isTouchDevice,
    isEmailLoginEnabled,
    isSSOLoginEnabled,
    email,
    password,
    isDisabled,
    loginState,
    errorState,
    logoutIframeRef,
    setEmail,
    setPassword,
    handleLoginWithUsernameAndPassword,
    handleLoginWithSSO,
  } = useLogin()

  return (
    <Box className={classes.root}>
      <Box pt={16} display="flex" justifyContent="center">
        {isSmallScreen ? <LogoMobile /> : <Logo />}
      </Box>

      <Box className={classes.loginContent}>
        <Typography variant="h5">Welcome!</Typography>

        {/* Errors */}
        {loginState === 'not-allowed' && <Alert severity="error">Email or password is not correct.</Alert>}
        {errorState && errorState !== 'submitting' && <Alert severity="error">{errorState}</Alert>}

        <Box component="div" display="flex" flexDirection="column" gap={2}>
          {/* Login with Email/Password */}
          {isEmailLoginEnabled && (
            <>
              <TextField
                id="email"
                label="Email"
                type="text"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                autoComplete="false"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key !== 'Enter') return
                  handleLoginWithUsernameAndPassword(email, password)
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
              <LoadingButton
                loading={loginState === 'submitting'}
                variant="contained"
                size="large"
                color="primary"
                disableElevation={true}
                disabled={isDisabled}
                onClick={() => handleLoginWithUsernameAndPassword(email, password)}
                fullWidth
              >
                Login
              </LoadingButton>
            </>
          )}

          {isEmailLoginEnabled && isSSOLoginEnabled && (
            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
              <Divider className={classes.divider} />
              <Box className={classes.dividerText}>OR</Box>
            </Box>
          )}

          {/* Login with SSO */}
          {isSSOLoginEnabled && (
            <LoadingButton
              loading={loginState === 'submitting-sso'}
              variant="outlined"
              size="large"
              color="primary"
              disableElevation={true}
              disabled={isDisabled}
              onClick={handleLoginWithSSO}
              fullWidth
            >
              Continue with Google
            </LoadingButton>
          )}

          {/* Logout Google with an iframe for Zipp only */}
          {isSmallScreen && isTouchDevice && (
            <iframe
              ref={logoutIframeRef}
              width={0}
              height={0}
              referrerPolicy="no-referrer"
              style={{ visibility: 'hidden' }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Login
