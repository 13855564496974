import { Queue } from '@quickcommerceltd/zipp'
import { doc } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { zimBackendDb } from '../../firebase'
import { GenericConverter } from '../../firebase/genericConverter'
import { useMyUser } from '../../hooks'

const queueConverter = new GenericConverter<Queue>()

export const useQueue = () => {
  const warehouseId = useMyUser((state) => state.user?.warehouseId)
  const [queue, isLoading, error] = useDocumentData<Queue>(
    doc(zimBackendDb, `queues/${warehouseId}`).withConverter(queueConverter)
  )

  return { queue, isLoading, error }
}
