import { makeStyles } from 'tss-react/mui'
import { themePalette } from '../Theme'

// TODO: migrate to emotion and styled
export default makeStyles()({
  container: {
    backgroundColor: themePalette.primary.main,
    position: 'fixed',
    height: '100%',
    width: '100%',
  },
  circularProgress: {
    color: 'white',
  },
  content: {
    width: '100%',
    padding: '0 48px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'table',
  },
  message: {
    color: 'white',
  },
  button: {
    background: 'white',
    color: themePalette.primary.main,
    padding: '8px 24px',
  },
})
