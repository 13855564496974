import { doc } from 'firebase/firestore'
import { zimBackendDb } from '../../../firebase'
import { getCollectionName } from './utilities'

// Paths
// =============================================
export const getOrderPath = (orderId: string) => {
  return `${getCollectionName(orderId)}/${orderId}`
}

// Queries
// =============================================
export const getOrderRef = (orderId: string) => {
  const path = getOrderPath(orderId)

  return doc(zimBackendDb, path)
}
