import { fetchAndActivate, getAll } from 'firebase/remote-config'
import { useEffect, useState } from 'react'
import { REQUEST_STATUS } from '../../consts/requestStatus'
import { zimBackendRemoteConfig } from '../../firebase'
import { logSentryError } from '../../utils/logHelper'
import type { ContextValue, FeatureFlagMap } from './types'

const useProvider = () => {
  const [fetchFeatureFlagsRequestStatus, setFetchFeatureFlagsRequestStatus] = useState(REQUEST_STATUS.NOT_INITIALISED)
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagMap>({})

  const fetchFeatureFlags = async () => {
    setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.PENDING)

    try {
      await fetchAndActivate(zimBackendRemoteConfig)

      const newFeatureFlags = Object.entries(getAll(zimBackendRemoteConfig)).reduce(
        (acc: FeatureFlagMap, [key, value]) => {
          acc[key] = JSON.parse(value.asString())

          return acc
        },
        {}
      )

      setFeatureFlags(newFeatureFlags)
      setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.FULFILLED)
    } catch (error) {
      setFetchFeatureFlagsRequestStatus(REQUEST_STATUS.REJECTED)
      logSentryError(error)
    }
  }

  useEffect(() => {
    fetchFeatureFlags()
  }, [])

  const value: ContextValue = featureFlags

  return {
    fetchFeatureFlagsRequestStatus,
    fetchFeatureFlags,
    value,
  }
}

export default useProvider
