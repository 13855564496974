import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore'

export class GenericConverter<T> implements FirestoreDataConverter<T> {
  toFirestore(object: WithFieldValue<T>): DocumentData {
    return object as DocumentData
  }

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      exists: snapshot.exists(),
      ...(snapshot.data(options) as T),
    }
  }
}

// Generic Converter to get just the documents data
export class GenericDataConverter<T> implements FirestoreDataConverter<T> {
  toFirestore(object: WithFieldValue<T>): DocumentData {
    return object as DocumentData
  }

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
    return snapshot.data(options) as T
  }
}
