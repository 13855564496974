import { Alert, Box, Button, Grid, Link, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { LoadingSpinner } from '../../Components/LoadingSpinner'
import { Logo } from '../../Components/Svg/Logo'
import { WarehousesSelectorComponent } from '../../Components/WarehousesSelectorComponent/WarehousesSelectorComponent'
import { useLogout } from '../../firebase/useLogout'
import { useSignupComplete } from '../../firebase/useSignupComplete'
import { useMyUser } from '../../hooks'

// TODO: migrate to emotion and styled
const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  loginContent: {
    background: '#ffffff',
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    width: '100%',
    justifyContent: 'space-between',
  },
}))

const SignupComplete = () => {
  const { classes } = useStyles()
  const { signupComplete, signupCompleteState } = useSignupComplete()
  const { logout } = useLogout()
  const email = useMyUser((state) => state.user?.email ?? '')
  const name = useMyUser((state) => state.user?.name ?? '')
  const [fullName, setFullName] = useState(name)
  const [warehouseId, setWarehouseId] = useState<string>(useMyUser.getState().user?.warehouseId ?? '')

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault()
    logout()
  }

  return (
    <Box className={classes.root}>
      <Box pt={7} display="flex" justifyContent="center">
        <Logo />
      </Box>

      <Box className={classes.loginContent}>
        <Typography variant="h5">Welcome!</Typography>

        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body2">Complete Details to continue</Typography>
          </Grid>
        </Grid>

        <Box mb={4} />
        {signupCompleteState && signupCompleteState !== 'submitting' && (
          <>
            <Alert severity="error">{signupCompleteState}</Alert>
            <Box mb={4} />
          </>
        )}
        {/* we want to check false instead of undefined because we want to wait until firebase is ready */}
        <TextField
          id="name"
          label="Full Name"
          variant="outlined"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
        <Box mb={4} />
        <WarehousesSelectorComponent
          onChange={(warehouseConfig) => warehouseConfig && setWarehouseId(warehouseConfig.id)}
        />

        <Link style={{ textAlign: 'right', marginTop: '10px' }} href="#" onClick={preventDefault}>
          Log In
        </Link>
        <Box mb={4} />
        <Button
          variant="contained"
          size="large"
          color="primary"
          disableElevation={true}
          disabled={signupCompleteState === 'submitting'}
          onClick={async () => {
            await signupComplete({
              fullName,
              email,
              warehouseId: warehouseId || '',
            })
          }}
        >
          {signupCompleteState === 'submitting' ? <LoadingSpinner style={{ color: 'white' }} size={20} /> : 'Log in'}
        </Button>
      </Box>
    </Box>
  )
}

export default SignupComplete
