import LogoutIcon from '@mui/icons-material/Logout'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import { Box, Container, IconButton, List, ListItemText, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { WarehouseType } from '@quickcommerceltd/zipp'
import { makeStyles } from 'tss-react/mui'
import { Permission } from '../../consts/PERMISSIONS'
import { ROUTES } from '../../consts/Routes'
import { useFeatureFlag } from '../../Containers/FeatureFlag'
import { useLogout } from '../../firebase/useLogout'
import { useHasPermission, useMyUser, useMyWarehouse } from '../../hooks'
import {
  CheckSquareIcon,
  CrossHairIcon,
  InboundIcon,
  LocationsIcon,
  MoveIcon,
  TruckIcon,
  UpdateIcon as ChangeWarehouseIcon,
} from '../Svg'
import MenuItem from './MenuItem'

// TODO: migrate to emotion and styled
const useStyles = makeStyles()((theme) => ({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
    flex: 1,
    height: '100vh',
    width: '80vw',
  },
  listContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    fontSize: '18px',
    lineHeight: 1.2,
    color: '#fff',
    fontWeight: 600,
  },
  userEmail: {
    fontSize: '12px',
    color: '#fff',
    fontWeight: 400,
    lineHeight: 1,
  },
  menuContainer: {
    flex: 7,
    overflow: 'scroll',
    fill: theme.palette.primary.main,
  },
  sblBadge: {
    fontSize: '8px',
    marginLeft: '8px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '1px 4px',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  open: boolean
  onClose: () => void
}

const SideDrawer = ({ open, onClose }: Props) => {
  const { classes } = useStyles()
  const hasPermission = useHasPermission()
  const userName = useMyUser((state) => state.user?.name)
  const userEmail = useMyUser((state) => state.user?.email)
  const warehouseType = useMyWarehouse((state) => state.warehouse?.type)
  const { logout } = useLogout()

  const {
    values: {
      isPickingInStoresEnabled,
      isZippStockAdjustmentEnabled,
      isExpirySweeperEnabled,
      isDiscrepancySweeperEnabled,
      isCycleCountV2Enabled,
      isCycleCountV3Enabled,
    },
  } = useFeatureFlag([
    'isPickingInStoresEnabled',
    'isZippStockAdjustmentEnabled',
    'isExpirySweeperEnabled',
    'isDiscrepancySweeperEnabled',
    'isCycleCountV2Enabled',
    'isCycleCountV3Enabled',
  ])

  const addSblBadge = <div className={classes.sblBadge}>SBL</div>

  return (
    <Drawer anchor="right" open={open} onClose={onClose} transitionDuration={100}>
      <Container className={classes.drawerContainer}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" pl={2} pr={1} bgcolor="#608CA1">
          <List className={classes.listContainer}>
            <ListItemText
              primary={
                <Typography variant="subtitle1" className={classes.userName}>
                  {userName}
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2" className={classes.userEmail}>
                  {userEmail}
                </Typography>
              }
            ></ListItemText>
          </List>
          <IconButton onClick={logout} sx={{ color: 'common.white' }}>
            <LogoutIcon />
          </IconButton>
        </Stack>
        <Box className={classes.menuContainer} onClick={onClose}>
          <List>
            {warehouseType === WarehouseType.STORE && (
              <MenuItem icon={<ShoppingBasketOutlinedIcon />} badge={addSblBadge} to={ROUTES.QUEUE_LISTENING}>
                Order Queue
              </MenuItem>
            )}

            {hasPermission(Permission.CAN_INBOUND) && (
              <MenuItem icon={<InboundIcon />} badge={addSblBadge} to={ROUTES.INBOUND_V3}>
                Inbound
              </MenuItem>
            )}

            {hasPermission(Permission.CAN_PUTAWAY) && (
              <MenuItem icon={<TruckIcon />} badge={addSblBadge} to={ROUTES.PUTAWAY}>
                Putaway
              </MenuItem>
            )}

            {warehouseType === WarehouseType.DISTRIBUTION && hasPermission(Permission.CAN_PICK) && (
              <MenuItem icon={<InboundIcon />} badge={addSblBadge} to={ROUTES.PICKING_V2}>
                Picking
              </MenuItem>
            )}

            {warehouseType === WarehouseType.STORE &&
              isPickingInStoresEnabled &&
              hasPermission(Permission.CAN_PICK) && (
                <MenuItem icon={<InboundIcon />} badge={addSblBadge} to={ROUTES.PICKING_V2}>
                  Stock Transfer
                </MenuItem>
              )}

            {isZippStockAdjustmentEnabled && hasPermission(Permission.CAN_ADJUST_STOCK) && (
              <MenuItem icon={<LocationsIcon />} badge={addSblBadge} to={ROUTES.STOCK_ADJUSTMENT_V2_ADJUST_STOCK}>
                Stock Adjustment
              </MenuItem>
            )}

            {hasPermission(Permission.CAN_MOVE_SKU) && (
              <MenuItem icon={<MoveIcon />} badge={addSblBadge} to={ROUTES.STOCK_ADJUSTMENT_V2_MOVE_SKU}>
                Move SKU
              </MenuItem>
            )}

            {hasPermission(Permission.CAN_SEE_STOCK_MOVEMENT_EXPLORER) && (
              <MenuItem icon={<CrossHairIcon />} badge={addSblBadge} to={ROUTES.STOCK_MOVEMENT_EXPLORER}>
                Stock Explorer
              </MenuItem>
            )}

            {isCycleCountV2Enabled && hasPermission(Permission.CAN_CYCLE_COUNT) && (
              <MenuItem
                icon={<CrossHairIcon />}
                badge={addSblBadge}
                to={isCycleCountV3Enabled ? ROUTES.CYCLE_COUNT_V3 : ROUTES.CYCLE_COUNT_V2}
              >
                Cycle Count
              </MenuItem>
            )}

            {isExpirySweeperEnabled && hasPermission(Permission.CAN_SWEEP_EXPIRED_ITEMS) && (
              <MenuItem icon={<CheckSquareIcon />} badge={addSblBadge} to={ROUTES.EXPIRY_SWEEPER}>
                Expiry Sweeper
              </MenuItem>
            )}

            {isDiscrepancySweeperEnabled && hasPermission(Permission.CAN_SWEEP_DISCREPANCIES) && (
              <MenuItem icon={<CheckSquareIcon />} badge={addSblBadge} to={ROUTES.DISCREPANCY_SWEEPER}>
                Discrepancy Sweeper
              </MenuItem>
            )}

            {hasPermission(Permission.CAN_CHANGE_STORE) && (
              <MenuItem icon={<ChangeWarehouseIcon />} to={ROUTES.WAREHOUSE_SELECTION}>
                Change Store
              </MenuItem>
            )}
          </List>
        </Box>
      </Container>
    </Drawer>
  )
}

export default SideDrawer
